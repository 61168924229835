import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';

const MapPage = () => {
    const mapRef = useRef(null); // Reference for the map
    const [data, setData] = useState([]); // State to hold fetched data
    const [startDate, setStartDate] = useState(''); // State for start date
    const [endDate, setEndDate] = useState(''); // State for end date

    useEffect(() => {
        // Initialize the map
        const initMap = () => {
            if (!mapRef.current) {
                mapRef.current = new window.ymaps.Map('map', {
                    center: [53.630561, 55.921474],
                    zoom: 10,
                });
                fetchData(); // Fetch data when the map is initialized
            }
        };

        // Load Yandex Maps API
        window.ymaps.ready(initMap);

        // Cleanup effect
        return () => {
            mapRef.current = null; // Clear the map reference on unmount
        };
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get("http://94.103.86.215:4000/database");
            setData(response.data); // Set fetched data to state
            addPlacemarks(response.data); // Add placemarks to the map
        } catch (error) {
            console.error("Ошибка при получении данных:", error.message);
        }
    };

    const addPlacemarks = (data) => {
        mapRef.current.geoObjects.removeAll(); // Clear existing placemarks
        const filteredData = data.filter(item => {
            const itemDate = new Date(item.timestamp);
            const start = new Date(startDate);
            const end = new Date(endDate);
            return (!startDate || itemDate >= start) && (!endDate || itemDate <= end);
        });

        filteredData.forEach(item => {
            const placemark = new window.ymaps.Placemark([item.width, item.longitude], {
                balloonContent: `<strong>${item.device}</strong><br>Температура: ${item.temperature} ℃<br>Влажность: ${item.humidity} %<br>Давление: ${item.pressure} Па <br>CO2: ${item.co2} Промиль<br>Азот: ${item.azot} .<br>Освещение: ${item.light} Люмень`
            });
            mapRef.current.geoObjects.add(placemark); // Add placemark to the map
        });
    };

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value); // Update start date
        addPlacemarks(data); // Re-add placemarks based on new date
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value); // Update end date
        addPlacemarks(data); // Re-add placemarks based on new date
    };

    return (
        <div>
            <h1>Карта с данными</h1>
            <div>   
                <input type="date" value={startDate} onChange={handleStartDateChange} />
                <input type="date" value={endDate} onChange={handleEndDateChange} />
            </div>
            <div id="map" style={{ height: '800px' }}></div>
        </div>
    );
};

export default MapPage;