// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* DataAnalysisPage.css */
.table {
    width: 100%;
    border-collapse: collapse;
}

.table th, .table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.table th {
    background-color: #f2f2f2;
}
input[type="date"] {
    padding: 10px; /* Add padding inside the input */
    border: 2px solid #3498db; /* Blue border color */
    border-radius: 5px; /* Rounded corners */
    font-size: 16px; /* Font size for better readability */
    width: 200px; /* Set a fixed width */
    background-color: #f9f9f9; /* Light background color */
    color: #333; /* Text color */
    transition: border-color 0.3s ease; /* Smooth transition for border color */
    margin: 10px 0; /* Add spacing between inputs */
}

input[type="date"]:focus {
    border-color: #1abc9c; /* Change border color on focus */
    outline: none; /* Remove default outline */
    background-color: #e8f5e9; /* Slightly different background on focus */
}

input[type="date"]::placeholder {
    color: #aaa; /* Placeholder text color */
    font-style: italic; /* Italicize placeholder text */
}`, "",{"version":3,"sources":["webpack://./src/DataAnalysisPage.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;IACI,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B;AACA;IACI,aAAa,EAAE,iCAAiC;IAChD,yBAAyB,EAAE,sBAAsB;IACjD,kBAAkB,EAAE,oBAAoB;IACxC,eAAe,EAAE,qCAAqC;IACtD,YAAY,EAAE,sBAAsB;IACpC,yBAAyB,EAAE,2BAA2B;IACtD,WAAW,EAAE,eAAe;IAC5B,kCAAkC,EAAE,uCAAuC;IAC3E,cAAc,EAAE,+BAA+B;AACnD;;AAEA;IACI,qBAAqB,EAAE,iCAAiC;IACxD,aAAa,EAAE,2BAA2B;IAC1C,yBAAyB,EAAE,2CAA2C;AAC1E;;AAEA;IACI,WAAW,EAAE,2BAA2B;IACxC,kBAAkB,EAAE,+BAA+B;AACvD","sourcesContent":["/* DataAnalysisPage.css */\n.table {\n    width: 100%;\n    border-collapse: collapse;\n}\n\n.table th, .table td {\n    border: 1px solid #ddd;\n    padding: 8px;\n    text-align: left;\n}\n\n.table th {\n    background-color: #f2f2f2;\n}\ninput[type=\"date\"] {\n    padding: 10px; /* Add padding inside the input */\n    border: 2px solid #3498db; /* Blue border color */\n    border-radius: 5px; /* Rounded corners */\n    font-size: 16px; /* Font size for better readability */\n    width: 200px; /* Set a fixed width */\n    background-color: #f9f9f9; /* Light background color */\n    color: #333; /* Text color */\n    transition: border-color 0.3s ease; /* Smooth transition for border color */\n    margin: 10px 0; /* Add spacing between inputs */\n}\n\ninput[type=\"date\"]:focus {\n    border-color: #1abc9c; /* Change border color on focus */\n    outline: none; /* Remove default outline */\n    background-color: #e8f5e9; /* Slightly different background on focus */\n}\n\ninput[type=\"date\"]::placeholder {\n    color: #aaa; /* Placeholder text color */\n    font-style: italic; /* Italicize placeholder text */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
