import React, { useState, useEffect } from "react";
import axios from "axios";
import "./App.css";
import LeftMenu from './LeftMenu';
import { Route, Routes } from 'react-router-dom';
import MapPage from './MapPage';
import DataAnalysisPage from './DataAnalysisPage';
import Graph3DPage from './Graph3DPage';

const App = () => {
    const [data, setData] = useState([]);
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);

    const fetchData = async () => {
        try {
            const response = await axios.get("http://94.103.86.215:4000/database");
            setData(response.data);
        } catch (error) {
            console.error("Ошибка при получении данных:", error.message);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const filteredData = data.filter((item) => {
        if ((!selectedStartDate && !selectedEndDate) ||
            (!selectedStartDate && item.timestamp <= selectedEndDate) ||
            (!selectedEndDate && item.timestamp >= selectedStartDate) ||
            (item.timestamp >= selectedStartDate && item.timestamp <= selectedEndDate)) {
            return true;
        }
        return false;
    });

    return (
        <div className="container">
            <LeftMenu isOpen={true} /> {/* Render LeftMenu statically */}
            <div className="content">
                <Routes>
                    <Route path="/map" element={<MapPage />} />
                    <Route path="/data-analysis" element={<DataAnalysisPage />} />
                    <Route path="/3d-graph" element={<Graph3DPage />} />
                    <Route path="*" element={
                        <>
                            <h1 className="header">Данные с газоанализатора</h1>
                            <input type="date" className="date-input" onChange={(e) => setSelectedStartDate(e.target.value)} />
                            <input type="date" className="date-input" onChange={(e) => setSelectedEndDate(e.target.value)} />
                            <table className="data-table">
                                <thead>
                                    <tr>
                                        <th>Номер устройства</th>
                                        <th>Температура ℃</th>
                                        <th>Влажность %</th>
                                        <th>Давление па</th>
                                        <th>СО2 в промилле</th>
                                        <th>Иной газ</th>
                                        <th>Освещение лк</th>
                                        <th>Долгота</th>
                                        <th>Широта</th>
                                        <th>Дата</th>
                                        <th>Время</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.device}</td>
                                            <td>{item.temperature}</td>
                                            <td>{item.humidity}</td>
                                            <td>{item.pressure}</td>
                                            <td>{item.co2}</td>
                                            <td>{item.azot}</td>
                                            <td>{item.light}</td>
                                            <td>{item.longitude}</td>
                                            <td>{item.width}</td>
                                            <td>{new Date(item.timestamp).toLocaleDateString()}</td>
                                            <td>{new Date(item.timestamp).toLocaleTimeString()}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </>
                    } />
                </Routes>
            </div>
        </div>
    );
};

export default App;